<template>
  <v-container id="user" fluid tag="section">
    <v-row>
      <v-col cols="12" md="12">
        <div class="text-h4 font-weight-light py-2"><b>Id:</b> {{ id }}</div>
        <div class="text-h4 font-weight-light py-2">
          <b>Order Id:</b>
          <span @click="gotoOrder(order_id)" style="cursor: pointer">{{
            order_id
          }}</span>
        </div>
        <div class="text-h4 font-weight-light py-2">
          <b>Driver Name:</b> {{ driver_name }}
        </div>
        <div class="text-h4 font-weight-light py-2">
          <b>Date Time:</b> {{ format_date(date_time) }}
        </div>
        <div class="text-h4 font-weight-light py-2">
          <b>Reason:</b> {{ reason }}
        </div>
        <div class="text-h4 font-weight-light py-2">
          <b>Email:</b> {{ email }}
        </div>
        <div class="text-h4 font-weight-light py-2">
          <b>Phone Number:</b> {{ phone_number }}
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12" class="text-left">
        <router-link :to="{ name: 'Driver Cancel Order' }">
          <v-btn type="button" color="primary" class="mr-0">Back</v-btn>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  props: ["id", "action"],
  data() {
    return {
      editUser: false,
      onlyView: false,
      order_id: "",
      driver_name: "",
      date_time: "",
      reason: "",
      email: "",
      phone_number: "",
    };
  },
  methods: {
    getDriverCancelorder(id) {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + "driver/cancel/order/edit/" + id
        )
        .then((response) => {
          if (response.status == 200) {
            this.order_id = response.data.driverCancelOrder.order_id;
            this.driver_name =
              response.data.driverCancelOrder.driver_name[0].first_name +
              " " +
              response.data.driverCancelOrder.driver_name[0].last_name;
            this.date_time = response.data.driverCancelOrder.date_time;
            this.reason = response.data.driverCancelOrder.reason;
            this.email = response.data.driverCancelOrder.driver_name[0].email;
            this.phone_number =
              response.data.driverCancelOrder.driver_name[0].phone_number;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    format_date(value) {
      if (value) {
        var res = new Date(value * 1000);
        return moment(res).format("DD/MM/YYYY h:m:s A");
      }
    },
    gotoOrder(id) {
      console.log(id);
      this.$router.push({
        name: "View Order",
        params: { id: id, action: "view" },
      });
    },
  },
  mounted() {
    if (this.id != undefined) {
      this.getDriverCancelorder(this.id);
      this.editUser = true;
    } else {
      this.editUser = false;
    }
    if (this.action == "view") {
      this.onlyView = true;
    }
  },
};
</script>